export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "盘点单号",
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "仓库名称",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 240,
  },
];
